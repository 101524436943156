var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isEmbedded
    ? _c(
        "v-toolbar",
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" },
            },
            [
              _c("pb-user-mode-selector"),
              _vm.showStandardLessons ||
              (_vm.userMode === "A" && !_vm.showStandardTeachers)
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-center my-auto",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { "open-delay": "500", bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.back.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fal fa-chevron-left"),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(_vm.$t("returnLabel"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            542579966
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("returnLabel")))])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showStandardLessons
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "close-on-click": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: onMenu }) {
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t("sendToLabel"),
                                            icon: "",
                                          },
                                        },
                                        { ...onMenu }
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("fal fa-arrow-up-from-square"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3435299453
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { on: { click: _vm.doExportPDF } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { text: "", color: "primary" } },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fal fa-file-pdf"),
                                      ]),
                                      _c("span", { staticClass: "ml-2" }, [
                                        _vm._v(_vm._s(_vm.$t("pdfLabel"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.doExportCSV } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { text: "", color: "primary" } },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fal fa-file-csv"),
                                      ]),
                                      _c("span", { staticClass: "ml-2" }, [
                                        _vm._v(_vm._s(_vm.$t("csvLabel"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }