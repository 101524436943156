















































import { Component } from 'vue-property-decorator';
import ReportingToolbar from './ReportingToolbar.vue';

@Component({
  components: {
    ReportingToolbar
  }
})
export default class ReportingBottomToolbar extends ReportingToolbar {
}
